.custom-navbar{
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 8vh;
  justify-content: space-around;
  align-items: center;
  font-family:Arial, Helvetica, sans-serif;
  font-style: italic;
}
.LOGO{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: center;
  font-size: large;
  background-color: antiquewhite;
  p{
    font-family: Verdana, sans-serif;
    height: 100%;
    width: 100%;
    background-color: aqua;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  h1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: black;
    margin-right: 1vw;
    background-color:aqua;
  }
}

.logoElements{
  display: flex;
  margin: 1vw;
}
.flex-menu{
  display: flex;
  width:50%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5vw;
  background-color: blueviolet;
}
.link{
  text-decoration: none;
  h2{
    color:black;
  }
}
.mobileMenu{
  display: none;
}
@media screen and (max-width:768px) {
  .mobileMenu{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .link-page{
    display: none;
  }
}