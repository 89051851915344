.home-grid{
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: auto 4fr auto;
  grid-template-rows: auto 4fr  auto;
  grid-template-areas: 
   "tops tops tops"
   "hempty1 contents hempty2"
   "bottoms bottoms bottoms"; 
 }
.tops{
  width: 100vw;
  height: 5vh;
  grid-area: tops;
}
.hempty1{
  width: 5vw;
  height: 90vh;
  grid-area:hempty1 ;
}
.contents{
  grid-area: contents;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.email{
  margin-top: 5vh;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: whitesmoke;
}
img{
  width: 100%;
} 
.hempty2{
  width: 5vw;
  height: 90vh;
  grid-area: hempty2;
}
.bottoms{
  width: 100vw;
  height: 5vh;
  grid-area: bottoms;
}
@media screen and (max-width:768px) {
.home-grid{
  display: grid;
  grid-template-columns: auto 4fr auto;
  grid-template-rows: auto 4fr  auto;
  grid-template-areas: 
    "tops tops tops"
    "hempty1 contents hempty2"
    "bottoms bottoms bottoms"; 
  }
}